$backgroundTeal: #90caf9;

html {
  background: $backgroundTeal;
}

* {
  box-sizing: border-box;
}

.parent {
  display: grid;
  grid-template-areas:
    'hrecos  metric'
    'station metric';
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.div1 {
  grid-area: 1 / 2 / 3 / 3;
}

.grid-layout {
  background: $backgroundTeal;
  display: grid;
  grid-template-columns: minmax(400px, 1fr) 3fr;
  grid-template-areas:
    'hrecos  metric'
    'station metric';
  //height: calc(100vh - 128px);
  grid-gap: 1em;
  min-height: calc(100vh - 150px);
  margin: 1em;
}

@media (max-width: 1500px) {
  .grid-layout {
    //border: 2px solid blue;
    grid-template-columns: auto;
    grid-template-areas:
      'metric metric'
      'metric metric'
      'station hrecos';
  }
}

@media (max-width: 800px) {
  .grid-layout {
    //border: 2px solid blue;
    grid-template-columns: auto;
    grid-template-areas:
      'metric'
      'metric'
      'hrecos'
      'station';
  }
}

.aboutHrecos {
  grid-area: hrecos;
}

.aboutStation {
  grid-area: station;
}

//.leftSide {
//  grid-area: hrecos;
//  flex-direction: column;
//
//  div:first-child {
//    margin-bottom: 1em;
//  }
//}

.rightSide {
  grid-area: metric;
  margin-right: 1em;
}

.embedded {
  background: $backgroundTeal;
  display: flex;
  flex-direction: row;
  height: 95vh;
  min-height: 800px;
  width: 115vh;
  margin: 1em auto;

  .left {
    margin: 1em;
  }
}
