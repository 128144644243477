.content,
.norrie,
.beczak,
.marist {
  display: block;
  position: relative;
  font-family: 'Arial', sans-serif !important;
  color: white;

  p {
    margin: 0;
  }
}

.norrie,
.piermont {
}

.beczak {
  text-align: justify;
}

.marist {
  text-align: justify;
}

.albany {
  min-height: 25vh;
}

p.weatherTime {
  text-align: left;
  margin-bottom: 10px;
}

div.marqueeContainer {
  overflow-x: hidden;
  white-space: nowrap;
  //position: absolute;
}

.weatherTicker {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  margin: 0;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.underline {
  text-decoration: underline;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maristImage {
  width: 38%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.piermontImageWrapper {
  float: left;
  width: 8vw;
  margin-right: 1rem;

  img {
    width: 8vw;
  }
}

.beczakImageWrapper {
  @extend .piermontImageWrapper;
  float: left;
  padding-right: 1rem;
}

.norrieImageWrapper {
  text-align: center;
  margin-top: 1rem;

  img {
    height: 13vh;
  }
}

.norrieImageText {
  text-align: center;
  font-size: 0.75vw;
  margin: 0;
}

.albanyImageWrapper {
  float: left;
  padding-right: 10px;

  img {
    width: 10vw;
  }
}

.piermontImage {
  width: auto;
  height: 100%;
  min-height: 190px;
  position: relative;
}

.beczakImage {
  width: auto;
  height: 100%;
  min-height: 190px;
  position: relative;
}
