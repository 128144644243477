$hrecos-blue: #7f99c7;

.core-layout__viewport {
  padding-top: 4rem;
}

.logo {
  height: 100%;
}

.acronym {
  width: 20rem;
}

.sponsor {
  height: 75%;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: 'Arial', sans-serif !important;
}

@media (max-width: 1300px) {
  &.title {
    font-size: 2.5rem;
  }
}

@media (max-width: 1100px) {
  &.title {
    font-size: 2rem;
  }
}

.appBar {
  background: $hrecos-blue;
  justify-content: space-between;
  height: 8rem;
}

.leftImageWrapper {
  height: 100%;
  width: 26%;
  display: flex;
}

.code {
  height: 60px;
  width: 60px;
}

.qrCodeWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 3px;

  a {
    width: 60px;
  }
}

.rightImageWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
}
